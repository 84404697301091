import React from 'react'
import '../styles/team.css'

function Team() {
    return(
        <>
            <div className='mrgB-60'>
                <div className='team-gallery'>
                    <div className='item-team team1'>                        
                        <div>
                            <h2>Roxana Pulido</h2>
                            <p>
                                Egresada de la Universidad Veracruzana con más 14 años de experiencia en la 
                                clínica de pequeñas especies, Roxana se ha especializado en Dermatología, 
                                Tratamiento y manejo de heridas en la práctica veterinaria, junto con ella, 
                                garantizamos la mejor atención para la piel de tus mascotas
                            </p>
                        </div>                        
                    </div>
                    <div className='item-team team2'> 
                        <div>
                            <h2>Luis Miguel Argueta</h2>
                            <p>
                                Egresado de la Facultad de Veterinaria de la UNAM, 13 años de
                                experiencia lo respaldan  en el desarrollo de capacidades veterinarias,
                                Miguel incorpora su conocimiento enfocado en la medicina preventiva y
                                ortopedia, garantizando la mejor de las atenciones para me la medicina
                                de primer contacto y la especialización en el diagnostico y seguimiento
                                ortopedico
                            </p>
                        </div>                       
                    </div>
                    <div className='item-team team3'>                        
                        <div>                            
                            <h2>Alejandra Molina</h2>
                            <p>
                                Egresada de la Universidad Nacional Autónoma de México, con más de 
                                13 años de experiencia en la atención veterinaria y especializándose  
                                Fisioterapia y rehabilitación, con el fin de lograr mejorar los procedimientos post quirúrgicos en ortopedia y movilidad en mascotas de cualquier edad, sin duda una pieza clave en el bienestar animal.
                            </p>
                        </div>                      
                    </div>
                    <div className='item-team team4'>                       
                        <div>
                            <h2>Axel Guzmán</h2>
                            <p className='team-quote'>
                              "Cada paciente es único y por lo tanto su atención quirúrgica así tiene que ser Única"
                            </p>
                            <p>
                                Forjado en la Universidad Nacional Autónoma de MÉXICO, desde hace 14 años, Axel cuenta 
                                con un amplio curriculum en procedimientos quirúrgicos y anestésicos, producción y salud 
                                animal, para el tratamiento de enfermedades de pequeñas especies,
                            </p>
                        </div>                       
                    </div>
                    <div className='item-team team5'>                       
                        <div>
                            <h2>Dulce Argueta</h2>
                            <p>
                                Formada desde hace 18 años en el área de ventas y marketing, 
                                involucrada en el cumplimiento y desarrollo de  planes de 
                                crecimiento, rentabilidad y manejo de personal, titulada como 
                                Administradora, Dulce siempre ha hecho énfasis en el buen trato 
                                a los colaboradores es sinónimo de éxito en el alcance de las 
                                metas organizacionales.
                            </p>
                        </div>                       
                    </div>
                </div>
            </div>        
        </>
    )
}

export default Team