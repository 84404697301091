import React from 'react'

import Hero from './hero';
import Objectives from './objectives';
import MidGallery from './midGallery';


function Home() {
    return(
        <>
            <Hero />
            <Objectives />
            <MidGallery />
        </>
    )
}

export default Home