import React from "react";
import { BrowserRouter, Routes , Route, Navigate } from "react-router-dom";
import Nav from './components/nav';
import Footer from './components/footer';
import Services from './components/services';
import Gallery from './components/gallery';
import Home from './components/home'
import Team from "./components/team";
import ModalBanner from "./components/modalBanner";

function App() {
  return (
    <>   
      <BrowserRouter>
      <Nav />  
      <ModalBanner />
        <Routes>          
          <Route path="/inicio" exact element={<Home />} />
          <Route path="/" element={<Navigate to ="/inicio" />}/>
          <Route path="/servicios" element={<Services/>} />
          <Route path="/galeria" element={<Gallery/>} />
          <Route path="/nuestro-equipo" element={<Team/>} />
        </Routes>
      <Footer />   
      </BrowserRouter>  
    </>
  );
}


export default App;
