import React from 'react'
import '../styles/gallery.css'

function Gallery() {
    return(
        <>
            <div class="gallery">
                <img src="https://i.postimg.cc/ZnxdGWdT/img-10.jpg" alt="" />
                <img src="https://i.postimg.cc/15y6prk4/img-14.jpg" alt="" />
                <img src="https://i.postimg.cc/Kj2TpP4q/img-16.jpg" alt="" />
                <img src="https://i.postimg.cc/PrKDK8Hy/img-17.jpg" alt="" />
                <img src="https://i.postimg.cc/tThZ6sDh/img-18.jpg" alt="" />
                <img src="https://i.postimg.cc/VsW0LbJw/img-19.jpg" alt="" />
                <img src="https://i.postimg.cc/FHBLX57J/img-20.jpg" alt="" />
                <img src="https://i.postimg.cc/JhHB16kY/img-21.jpg" alt="" />
                <img src="https://i.postimg.cc/y8kDPQv6/img-22.jpg" alt="" />
                <img src="https://i.postimg.cc/rFhd5XGY/img-23.jpg" alt="" />
                <img src="https://i.postimg.cc/c41gK43n/img-24.jpg" alt="" />                
                <img src="https://i.postimg.cc/9MmP0yHq/img-1.jpg" alt="" />                
                <img src="https://i.postimg.cc/d3XG5Xk8/img-2.jpg" alt="" />                
                <img src="https://i.postimg.cc/FKQVt2Pp/img-27.jpg" alt="" />                
                <img src="https://i.postimg.cc/JhHB16kY/img-21.jpg" alt="" />                
                <img src="https://i.postimg.cc/cC6QMZHx/img-28.jpg" alt="" />                
                <img src="https://i.postimg.cc/htm15S0h/img-29.jpg" alt="" />                
                <img src="https://i.postimg.cc/dtk2hqpn/img-3.jpg" alt="" />                
                <img src="https://i.postimg.cc/HnQMdt6M/img-31.jpg" alt="" />                
                <img src="https://i.postimg.cc/qR1s3gpD/img-30.jpg" alt="" />                
                <img src="https://i.postimg.cc/8P9b4FMq/img-7.jpg" alt="" />                
                {/* <img src="https://i.postimg.cc/B6Fkj8jT/img-10.jpg" alt="" />
                <img src="https://i.postimg.cc/Fzg6x2g1/img-11.jpg" alt="" />
                <img src="https://i.postimg.cc/sfpbyhdy/img-12.jpg" alt="" />
                <img src="https://i.postimg.cc/prt7z2jg/img-13.jpg" alt="" />
                <img src="https://i.postimg.cc/6pHDgxsv/img-14.jpg" alt="" />
                <img src="https://i.postimg.cc/1z2bR03R/img-16.jpg" alt="" />
                <img src="https://i.postimg.cc/YShJwDky/img-17.jpg" alt="" /> */}
            </div>
        </>
    )
}

export default Gallery