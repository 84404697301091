import React from 'react'
import '../styles/objectives.css'
function Objectives() {
    return(
        <>
            <div className="container-3-items">
                <div className='item'>
                    <div>
                        <img src="https://i.postimg.cc/nVBf91Bf/perro-trapo.jpg" className='avatars' alt="" />
                    </div>
                    <div>  
                        <div className="txt-md">
                            <h2>Misión:</h2>
                            <p>
                                Ofrecer la mejor atención veterinaria de calidad,
                                brindando un servicio eficiente y profesional para mejorar la salud
                                y el bienestar de nuestros pacientes.
                            </p>
                        </div>                  
                    </div>
                </div>
                <div className='item'>                    
                    <div>
                        <img src="https://i.postimg.cc/ZnxdGWdT/img-10.jpg" className='avatars' alt="" />
                    </div>
                    <div>
                        <div className="txt-md">
                            <h2>Visión:</h2>
                            <p>
                                Convertirnos en el centro veterinario de referencia
                                en el norte de la Ciudad de México, por regirse normas de bienestar animal
                                y de excelencia profesional.
                            </p>
                        </div>
                    </div>                   
                </div>
                <div className='item'>
                    <div>
                        <img src="https://i.postimg.cc/4xBYkGxP/conejo-test.jpg" className='avatars' alt="" />
                    </div>
                    <div>
                        <div className='txt-md'>
                            <h2>Valores:</h2>                       
                                <p>
                                    - Compromiso con nuestros pacientes y sus dueños {'\u00A0'} -  {'\u00A0'}                                
                                    Respeto por la vida animal {'\u00A0'} -  {'\u00A0'}                          
                                    Ética profesional {'\u00A0'} - {'\u00A0'}                                
                                    Integridad {'\u00A0'} - {'\u00A0'}                                 
                                    Trabajo en equipo {'\u00A0'} - {'\u00A0'}                                 
                                    Responsabilidad Social
                                </p>
                        </div>                   
                    </div>
                </div>
            </div>
        </>
    )
}


export default Objectives